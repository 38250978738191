
































import {
	Component,
	Emit,
	Prop,
	Watch,
} from 'vue-property-decorator';
import MainMixin from '@/mixing/MixinMain.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import AppDataTable from '@/components/util/DataTable.vue';
import { DialogTableClass as Text } from '@/config/Text.json';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import { AxiosResponse } from 'axios';
import { IAgentCateg } from '@/model/agent/IAgentCateg';
import { IAudioTip } from '@/model/agent/IAudioTip';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';

@Component({
	name: 'DialogTableClass',
	components: {
		AppDialog,
		AppDataTable,
	},
})
export default class DialogTableClass extends MainMixin {
	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isOpen!: boolean;
	@Prop({
		type: String,
		required: true,
	})
	readonly classSelected!: string;
	@Prop({
		type: Number,
		required: false,
	})
	readonly tasSelected!: number;
	public agentSelected = '';
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	//public tas = 0;

	mounted(): void {
		this.agentSelected = this.$route.params.agent;
		if (this.isOpen === true) {
			this.getData();
		}
	}
	@Watch('isOpen')
	public changedIsOpen(): void {
		if (this.isOpen === true) {
			this.getData();
		}
	}
	public get title(): string {
		let title = this.$t("DialogTableClass.title") as string;
		title = Util.replaceTextWith(
			title,
			'$1',
			this.agentSelected
		);
		title = Util.replaceTextWith(
			title,
			'$2',
			this.classSelected
		);
		return title;
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(
				`vue-xentric/agente/Subca-Clas?${this.getFilterUrl}`
			);
		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/evCantAudiosTipoLlamAgente?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2])
			.then((responses) => {
				console.log("response subca-class: ", responses[0]);
				console.log("response evCantAudios: ", responses[1]);
				const response_1: AxiosResponse = responses[0] as AxiosResponse;
				const groupByAgent: Record<
					string,
					Array<IAgentCateg>
				> = Util.reduceByProperty<
					IAgentCateg,
					'agente'
				>(response_1.data, 'agente');
				const groupByLlam: Record<
					string,
					Array<IAgentCateg>
				> = Util.reduceByProperty<
					IAgentCateg,
					'tipo_llamada'
				>(
					groupByAgent[this.agentSelected],
					'tipo_llamada'
				);
				const pruebaByAgent: Record<
					string,
					Array<IAudioTip>
				> = Util.reduceByProperty<
					IAudioTip,
					'agente'
				>(responses[1].data, 'agente');
				const pruebaByTipLlam: Record<
					string,
					Array<IAudioTip>
				> = Util.reduceByProperty<
					IAudioTip,
					'tipo_llamada'
				>(
					pruebaByAgent[this.agentSelected],
					'tipo_llamada'
				);
				const label = Util.replaceTextWith(
					this.classSelected,
					' ',
					'_'
				);
				
				console.log(pruebaByTipLlam);
				const dataTable: Array<IDataTable> = [];
				//let acum = 0;
				let num_llam = pruebaByTipLlam[label].length;
				for (let item of groupByLlam[label]) {
					const row: IDataTable = {};
					let puntaje_base = item.PuntajeIdeal / num_llam;
					row['cat'] = item.categoria;
					row['sub'] = item.subcategoria;
					row['cum'] = `${(item.PuntajeObtenido / puntaje_base).toFixed()} de ${num_llam}`;
					row['no'] = `${((item.PuntajeIdeal - item.PuntajeObtenido) / puntaje_base).toFixed()} de ${num_llam}`;
					dataTable.push(row);
				}
				// this.tas = parseFloat(
				// 	(
				// 		acum / groupByLlam[label].length
				// 	).toFixed(1)
				// );
				this.header = Object.keys(dataTable[0]).map(
					(
						key: string,
						index: number
					): IHeaderTable<IDataTable> => {
						let text = this.$t("DialogTableClass.headerClass." + index.toString()) as string;
						return {
							text,
							value: key,
						};
					}
				);
				this.rows = dataTable;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenOnClose(): void {
		this.onClosed();
	}

	public listenClickClass(payload: IDataTable) {
		this.onClosed();
		this.onClickRow(payload);
	}

	@Emit()
	public onClosed(): boolean {
		return true;
	}

	@Emit()
	public onClickRow(payload: IDataTable): IDataTable {
		return payload;
	}
}
