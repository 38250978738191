import { render, staticRenderFns } from "./EvaluationDataTable.vue?vue&type=template&id=25b3a4b2&scoped=true&"
import script from "./EvaluationDataTable.vue?vue&type=script&lang=ts&"
export * from "./EvaluationDataTable.vue?vue&type=script&lang=ts&"
import style0 from "./EvaluationDataTable.vue?vue&type=style&index=0&id=25b3a4b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b3a4b2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VChip,VDataTable,VSelect,VTextField})
