









































































import {
	Component,
	Emit,
	Prop,
	Vue,
} from 'vue-property-decorator';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { DataTable as Text } from '@/config/Text.json';
import Util from '@/utils/Util';
import { IItemSelect } from '@/model/util/IItemSelect';
@Component({
	name: 'EvaluationDataTable',
})
export default class EvaluationDataTable extends Vue {
	public noDataText = this.$t("DataTable.noDataText");
	public noDataFound = this.$t("DataTable.noDataFound");
	public elementPerPage = this.$t("DataTable.elementPerPage");
	public countElementPage = this.$t("DataTable.countElementPage");
	public searchText = this.$t("DataTable.searchText");
	public noCumple = this.$t("DataTable.noCumple");
	public cumple = this.$t("DataTable.cumple");
	public search = '';
	public isInput = false;
	public estItems: Array<IItemSelect> = [
		{
			text: this.$t("DataTable.aceptado") as string,
			value: 'ACEPTADO',
		},
		{
			text: this.$t("DataTable.rechazado") as string,
			value: 'RECHAZADO',
		},
	];
	@Prop({
		type: Array as () => Array<IDataTable>,
		required: true,
	})
	readonly data!: Array<IDataTable>;
	@Prop({
		type: Array as () => Array<IHeaderTable<IDataTable>>,
		required: true,
	})
	readonly titles!: Array<IHeaderTable<IDataTable>>;

	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isLoading!: boolean;

	public getItemEval(robot: number): Array<IItemSelect> {
		const array: Array<IItemSelect> = [
			{
				text: this.$t("DataTable.sinCambio") as string,
				value: '',
			},
		];
		if (robot === 0) {
			array.push({
				text: this.$t("DataTable.cumple") as string,
				value: '1',
			});
		} else {
			array.push({
				text: this.$t("DataTable.noCumple") as string,
				value: '0',
			});
		}
		return array;
	}
	public getTextCountElementPerPage(
		to: number,
		end: number
	): string {
		let text = Util.replaceTextWith(
			this.countElementPage as string,
			'$1',
			to.toString()
		);
		text = Util.replaceTextWith(
			text,
			'$2',
			end.toString()
		);
		return text;
	}
	get headers(): Array<IHeaderTable<IDataTable>> {
		let output: Array<IHeaderTable<IDataTable>> = [];

		if (
			this.data.length > 0 &&
			Object.keys(this.data[0]).length ===
				this.titles.length
		) {
			for (let header of this.titles) {
				if (
					header.text !== 'extra' &&
					header.text !== 'id' &&
					header.text !== '_id' &&
					header.text !== 'mt_id'
				) {
					output.push(header);
				}
			}
		}
		return output;
	}

	filterByText(
		value: string,
		search: string,
		item: IDataTable
	) {
		return (
			value != null &&
			search != null &&
			value.toString().indexOf(search) !== -1
		);
	}
	public resetExtra(item: IDataTable) {
		//console.log(item);

		if (item.eval === '') {
			item.tun = '';
			item.active = false;
			item.extra = '';
		}
	}
	@Emit()
	public onRowClicked(data: string, item: IDataTable) {
		return item.item;
	}
}
